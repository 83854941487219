// @ts-nocheck
"use client";

console.warn(
  "⚠️ Avoid importing components from 'index.js' for better performance. This practice is deprecated and may be removed in the future."
);

export * as _Builtin from "./_Builtin";
export * from "./devlinkContext";
export * from "./interactions";
export * from "./utils";
export * from "./devlink";
export * from "./Footer";
export * from "./FooterFr";
export * from "./JdsSearchFr";
export * from "./JdsSearchNl";
export * from "./Navigation";
export * from "./NavigationFr";
export * from "./SearchBar";
export * from "./SearchBarFr";
